export default {
  title: { en: "Cheekbone", he: "עצמות לחיים" },
  paragraph1: {
    en: "Cheekbone fillers treatment is a cosmetic procedure that uses injectable fillers to enhance the appearance of the cheekbones. The fillers are made of hyaluronic acid, a substance that occurs naturally in the body, and are designed to add volume to the cheeks and restore their youthful contour. The treatment is non-invasive, meaning it does not require any incisions or surgical intervention, and can be completed in as little as 30 minutes.",
    he: "בוטוקס הוא טיפול אסתטי הכולל הזרקה של חלבון מטוהר הנקרא בוטולינום טוקסין לשרירים ספציפיים. הבוטוקס פועל על ידי הרפיה זמנית של השרירים הגורמים לקמטים, וכתוצאה מכך מעניק מראה חלק, רענן וצעיר יותר. הבוטוקס משמש לרוב לטיפול בקמטים וקמטים במצח, בין הגבות ומסביב לעיניים (המכונה רגל עורב), אך במקרים מסויימים משמש לעוד אזורים כמו מסביב לשפתיים(קמטים נפוצים במעשנים) בסנטר שריר המאסטר ועוד.  הטיפול מתבצע על ידי רופא מנוסה ומוסמך ואורך בדרך כלל דקות ספורות. ההשפעות של הבוטוקס אינן קבועות ונמשכות בדרך כלל  3-6 חודשים (משתנה בין אדם לאדם). למשל, ככל שהאדם מבצע יותר פעילות גופנית כך פירוק הבוטוקס יהיה מהיר יותר. עוד משתנים הינם גיל , אלסטיות העור, עומק הקמט ועוד.",
  },
  paragraph2: {
    en: "During the treatment, a small amount of filler is carefully injected into the cheekbone area, using a fine needle. The filler is then smoothed and molded to create the desired shape and volume. The entire process is relatively painless, and most people only experience some mild discomfort and swelling, which should subside within a few days.",
    he: "החדשות הטובות הן שכאשר הופכים את הטיפול לחלק משגרת טיפוח העור מרווחי הזמן בין טיפול לטיפול גדלים.",
  },
  paragraph3: {
    en: "One of the key benefits of cheekbone fillers is that they provide instant results. Unlike traditional surgical procedures, there is no downtime, and patients can return to their normal activities right away. The results of the treatment can last anywhere from 6 to 12 months, depending on the type of filler used, and the individual's skin type, lifestyle, and age.",
    he: "לאחר שבועיים נזמן אותך לביקורת על מנת לוודא את יעילות הטיפול ושביעות הרצון.",
  },
  paragraph4: {
    en: "It is important to note that cheekbone fillers are not a permanent solution, and touch-up treatments will be needed to maintain the desired results. However, because the fillers are gradually absorbed by the body over time, there is no risk of scarring or permanent damage. The treatment is also reversible, which means that if the patient is not happy with the results, the filler can be dissolved.",
    he: 'כמו בכל טיפול רפואי פולשני יכולות להופיע תופעות לוואי. כבר אוכל להרגיע ולומר שטיפול בוטוקס הינו בטוח במידה ונעשה במיומנות ואך ורק ע"י רופא.',
  },
  paragraph5: {
    en: "Finally, it is crucial to choose a qualified and experienced practitioner for cheekbone fillers treatment. This will ensure that the treatment is performed safely and effectively, and that the results meet the patient's expectations. The practitioner should have extensive training and experience in facial anatomy and cosmetic procedures, and should be able to provide detailed information about the potential risks and benefits of the treatment.",
    he: "חלק מתופעות הלוואי האפשריות כוללות נפיחות קלה באזור ההזרקה שחולפות זמן קצר לאחר הטיפול,לעיתים שטפי דם קלים שחולפים תוך מספר ימים , אי סימטריה אותה ניתן לתקן בביקורת וכאב ראש שחולף במהרה.",
  },
};
