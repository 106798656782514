import React from "react";
import classNames from "classnames";

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, ...inputProps }, ref) => {
    const classes = classNames(
      "block w-full rounded-md border border-secondary py-3 px-4 text-black shadow-sm focus:border-primary focus:ring-primary",
      className
    );
    return <textarea ref={ref} className={classes} {...inputProps} />;
  }
);
