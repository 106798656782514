import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Lang } from "lib/types";
import { AppRoutePaths } from "./routes";

export const LanguageRedirectRoute: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes(Lang.he) || pathname.includes(Lang.en)) {
      document.documentElement.lang = pathname.includes(Lang.he)
        ? Lang.he
        : Lang.en;
    }
  }, [pathname]);

  return pathname === "/" ? (
    <Navigate to={AppRoutePaths.homeHE.url + "#home"} />
  ) : (
    <Outlet />
  );
};
