export default {
  title: { en: "Botox", he: "בוטוקס" },
  paragraph1: {
    en: "Botox is a cosmetic treatment that involves injecting a small amount of a substance called Botulinum toxin into specific muscles in the face. The purpose of Botox is to temporarily paralyze or relax those muscles, leading to a reduction in wrinkles and fine lines.",
    he: "בוטוקס הוא טיפול אסתטי הכולל הזרקה של חלבון מטוהר הנקרא בוטולינום טוקסין לשרירים ספציפיים. הבוטוקס פועל על ידי הרפיה זמנית של השרירים הגורמים לקמטים, וכתוצאה מכך מעניק מראה חלק, רענן וצעיר יותר. הבוטוקס משמש לרוב לטיפול בקמטים וקמטים במצח, בין הגבות ומסביב לעיניים (המכונה רגל עורב), אך במקרים מסויימים משמש לעוד אזורים כמו מסביב לשפתיים(קמטים נפוצים במעשנים) בסנטר שריר המאסטר ועוד.  הטיפול מתבצע על ידי רופא מנוסה ומוסמך ואורך בדרך כלל דקות ספורות. ההשפעות של הבוטוקס אינן קבועות ונמשכות בדרך כלל  3-6 חודשים (משתנה בין אדם לאדם). למשל, ככל שהאדם מבצע יותר פעילות גופנית כך פירוק הבוטוקס יהיה מהיר יותר. עוד משתנים הינם גיל , אלסטיות העור, עומק הקמט ועוד.",
  },
  paragraph2: {
    en: 'Botox is most commonly used on the forehead, between the eyebrows (known as the "11\'s"), and around the eyes. The treatment is quick, usually taking only 15 to 30 minutes, and involves a series of tiny injections into the targeted muscle. The results of Botox are typically visible within a few days, and can last for up to 4 months, after which the treatment will need to be repeated.',
    he: "החדשות הטובות הן שכאשר הופכים את הטיפול לחלק משגרת טיפוח העור מרווחי הזמן בין טיפול לטיפול גדלים.",
  },
  paragraph3: {
    en: 'While Botox is generally considered safe, it is important to choose a qualified and experienced practitioner to administer the treatment. This is because the wrong dose or placement of the injections can result in unwanted side effects such as drooping eyelids or a "frozen" appearance. To minimize the risk of these side effects, it is important to have a thorough consultation with your practitioner before the treatment, and to follow all post-treatment instructions carefully.',
    he: "לאחר שבועיים נזמן אותך לביקורת על מנת לוודא את יעילות הטיפול ושביעות הרצון.",
  },
  paragraph4: {
    en: "Botox is not suitable for everyone, and certain medical conditions or medications can increase the risk of side effects. Some people may also experience mild discomfort or pain during the injections, although this can usually be managed with a local anesthetic. Before having Botox, it is important to discuss your medical history and any concerns you may have with your practitioner.",
    he: 'כמו בכל טיפול רפואי פולשני יכולות להופיע תופעות לוואי. כבר אוכל להרגיע ולומר שטיפול בוטוקס הינו בטוח במידה ונעשה במיומנות ואך ורק ע"י רופא.',
  },
  paragraph5: {
    en: "In conclusion, Botox is a popular cosmetic treatment that can help to reduce the appearance of wrinkles and fine lines. However, it is important to choose a qualified practitioner, follow all post-treatment instructions, and discuss any concerns or medical conditions with your practitioner before the treatment. With proper care, Botox can provide a safe and effective solution for achieving a more youthful, refreshed appearance.",
    he: "חלק מתופעות הלוואי האפשריות כוללות נפיחות קלה באזור ההזרקה שחולפות זמן קצר לאחר הטיפול,לעיתים שטפי דם קלים שחולפים תוך מספר ימים , אי סימטריה אותה ניתן לתקן בביקורת וכאב ראש שחולף במהרה.",
  },
};
