import { HashLink } from "react-router-hash-link";
import avatar from "assets/images/avatar.png";
import classNames from "classnames";
import { Button } from "components/ui/Button";
import { useLang } from "hooks/useLang";
import { scrollWithOffset } from "lib/utils";
import content from "../content";

export const QuestionsBanner: React.FC = () => {
  const { lang, isHebrew } = useLang();

  return (
    <>
      <div className="hidden md:block w-full rounded-md bg-black bg-opacity-80 p-2 shadow-lg sm:p-3">
        <div
          className={classNames(
            "flex flex-wrap items-center justify-between",
            isHebrew && "flex-row-reverse"
          )}
        >
          <div
            className={classNames(
              "flex items-center",
              isHebrew && "flex-row-reverse"
            )}
          >
            <span className="rounded-full h-14 w-14">
              <img src={avatar} alt="avatar" />
            </span>
            <div className={isHebrew ? "mr-3" : "ml-3"}>
              <p className="text-lg font-medium text-white">
                {content.heading[lang]}
              </p>
              <p className="text-md text-white">{content.subtitle[lang]}</p>
            </div>
          </div>
          <HashLink smooth to="#contact" scroll={(el) => scrollWithOffset(el)}>
            <Button>{content.contactUs[lang]}</Button>
          </HashLink>
        </div>
      </div>
      <div className="md:hidden w-full rounded-md bg-black bg-opacity-80 p-4 shadow-lg sm:p-3 flex flex-col gap-2">
        <div
          className={classNames(
            "flex gap-2 items-center",
            isHebrew && "flex-row-reverse"
          )}
        >
          <span className="rounded-full h-14 w-14">
            <img src={avatar} alt="avatar" />
          </span>
          <p className="text-lg font-medium text-white">
            {content.heading[lang]}
          </p>
        </div>
        <p className="text-md text-white">{content.subtitle[lang]}</p>
        <HashLink
          smooth
          to="#contact"
          scroll={(el) => scrollWithOffset(el)}
          className="w-full"
        >
          <Button className="w-full">{content.contactUs[lang]}</Button>
        </HashLink>
      </div>
    </>
  );
};
