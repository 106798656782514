import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import classNames from "classnames";
import { Button } from "components/ui/Button";
import { useLang } from "hooks/useLang";
import { useOnHoverOutside } from "hooks/useOnHoverOutside";
import { scrollWithOffset } from "lib/utils";
import content from "../content";
import { navigation } from "./navigation";

export const NavigationMenu: React.FC = () => {
  const { lang, isHebrew } = useLang();
  const { hash } = useLocation();

  const dropdownRef = useRef(null); // Create a reference for dropdown container
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);

  // Function to close dropdown
  const closeHoverMenu = () => {
    setMenuDropDownOpen(false);
  };

  useOnHoverOutside(dropdownRef, closeHoverMenu); // Call the hook

  return (
    <ul
      id="myMenu"
      className={classNames(
        "flex gap-4 hidden md:flex",
        isHebrew && "flex-row-reverse"
      )}
    >
      {Object.entries(navigation).map(([key, value]) => (
        <li
          key={key}
          className={classNames(
            hash === value.href ? "text-primary" : "text-secondary",
            "md:text-xl font-medium cursor-pointer flex items-center gap-1 relative",
            isHebrew && "flex-row-reverse"
          )}
        >
          <HashLink
            smooth
            to={`/${lang}/${value.href}`}
            scroll={(el) => scrollWithOffset(el)}
          >
            {value.name[lang]}
          </HashLink>
          {value.children && (
            <div ref={dropdownRef}>
              <Button
                variant="label"
                onMouseOver={() => setMenuDropDownOpen(true)}
              >
                <ArrowDownIcon className="h-4 w-4 inline-block mt-1 color-primary" />
              </Button>
              {isMenuDropDownOpen && <MenuDropdown />}
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

const MenuDropdown = () => {
  const { lang, isHebrew } = useLang();

  return (
    <div
      className={classNames(
        "absolute top-0 right-0 mt-6 mr-4 md:mr-0 z-[9999] w-max",
        isHebrew && "left-0"
      )}
    >
      <div className="bg-black text-white shadow-lg rounded-lg py-4 px-4">
        <ul
          className={classNames("flex flex-col gap-2", isHebrew && "items-end")}
        >
          {Object.entries(content.treatments).map(([key, value]) => (
            <li key={key} className="hover:text-primary w-max">
              <HashLink
                smooth
                to={`/${lang}/treatments/${value.id}`}
                scroll={(el) => scrollWithOffset(el)}
              >
                {value.title[lang]}
              </HashLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
