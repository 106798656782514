import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as WhatsAppIcon } from "assets/icons/whatsapp.svg";

export default {
  title: { en: "Contact", he: "צור קשר" },
  subtitle1: { en: "Contact information", he: "פרטי קשר" },
  subtitle2: { en: "Send us a message", he: "שלח לנו הודעה" },
  description: {
    en: "Our clinic is dedicated to helping you achieve your aesthetic goals and boost your confidence. To schedule a consultation or to learn more about the treatments we offer, please contact us using the information below:",
    he: 'חשוב לנו לעזור לך להשיג את התוצאות הטובות והנכונות ביותר עבורך. כדי לתזמן פגישת ייעוץ ללא תשלום או ללמוד עוד על הטיפולים שאנו מציעים, אנא צרי קשר עם ד"ר מעין רחמים.',
  },
  phone: { en: "Phone number", he: "מספר טלפון" },
  email: { en: "Email", he: "דואר אלקטרוני" },
  form: {
    fullName: { en: "Full name", he: "שם מלא" },
    email: { en: "Email", he: "דואר אלקטרוני" },
    message: { en: "Message", he: "הודעה" },
    send: { en: "Send", he: "שלח" },
    phone: { en: "Phone", he: "מספר טלפון" },
    optional: { en: "Optional", he: "אופציונלי" },
    maxChars: { en: "Max 500 characters", he: "מקסימום 500 תווים" },
    success: {
      en: "Your message has been sent successfully.",
      he: "ההודעה שלך נשלחה בהצלחה.",
    },
    error: {
      en: "There was an error sending your message. Please try again later.",
      he: "אירעה שגיאה בעת שליחת ההודעה שלך. אנא נסה שוב מאוחר יותר.",
    },
  },
  contactDetails: [
    { name: "phone", value: "050 354 3940", prefix: "tel", Icon: PhoneIcon },
    {
      name: "email",
      value: "dr.maayanrahamim@gmail.com",
      prefix: "mailto",
      Icon: EnvelopeIcon,
    },
  ],
  socialMedia: [
    // {
    //   name: "facebook",
    //   url: "https://www.facebook.com/triada.mg",
    //   Icon: FacebookIcon
    // },
    {
      name: "instagram",
      url: "https://www.instagram.com/dr.maayan",
      Icon: InstagramIcon,
    },
    {
      name: "whatsapp",
      url: "https://wa.me/972503543940",
      Icon: WhatsAppIcon,
    },
  ],
};
