import { useState } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Drawer } from "antd";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import classNames from "classnames";
import { Button } from "components/ui/Button";
import { useLang } from "hooks/useLang";
import { scrollWithOffset } from "lib/utils";
import content from "../content";
import { navigation } from "./navigation";

interface MobileMenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({ open, setOpen }) => {
  const { hash } = useLocation();
  const { lang, isHebrew } = useLang();
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={() => setOpen(false)}
      open={open}
      className="font-sans"
    >
      <div className="flex flex-col gap-2 mb-4">
        <div
          className={classNames(
            "flex justify-between items-center pb-2 border-b border-b-primary",
            isHebrew && "flex-row-reverse"
          )}
        >
          <h3 className="text-xl font-medium">{content.title[lang]}</h3>
          <Button variant="label" onClick={() => setOpen(false)}>
            <XMarkIcon className="h-6 w-6" />
          </Button>
        </div>
      </div>
      <nav>
        <ul
          className={classNames(
            "flex flex-col gap-2",
            isHebrew && "text-right"
          )}
        >
          {Object.entries(navigation).map(([key, value]) => (
            <li
              key={key}
              className={classNames("text-lg font-medium cursor-pointer")}
            >
              <div
                className={classNames(
                  "flex gap-2",
                  isHebrew && "flex-row-reverse"
                )}
              >
                <HashLink
                  className={classNames(
                    "block",
                    hash === value.href ? "!text-primary" : "text-black"
                  )}
                  smooth
                  to={`/${lang}/${value.href}`}
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => setOpen(false)}
                >
                  {value.name[lang]}
                </HashLink>
                {value.children && (
                  <Button
                    variant="label"
                    onClick={() => setMenuDropDownOpen(!isMenuDropDownOpen)}
                  >
                    <ArrowDownIcon className="h-4 w-4 inline-block mt-1 color-primary" />
                  </Button>
                )}
              </div>
              {value.children && isMenuDropDownOpen && (
                <ul className="flex flex-col gap-2 py-2">
                  {Object.entries(value.children).map(([key, value]) => (
                    <li
                      key={key}
                      className={classNames(
                        "ml-6 mr-6 text-lg font-medium cursor-pointer"
                      )}
                    >
                      <HashLink
                        className={classNames(
                          "block",
                          hash === value.href ? "!text-primary" : "text-black"
                        )}
                        smooth
                        to={`/${lang}/treatments${value.href}`}
                        scroll={(el) => scrollWithOffset(el)}
                        onClick={() => setOpen(false)}
                      >
                        {value.name[lang]}
                      </HashLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <ul
        className={classNames(
          "flex space-x-12 list-none pl-0 mt-6 border-t border-t-primary pt-6",
          isHebrew && "justify-end"
        )}
      >
        {content.socialMedia.map((socialMedia) => (
          <li key={socialMedia.name} className="pl-0">
            <a
              className="text-primary hover:text-primary"
              target="_blank"
              href={socialMedia.url}
              rel="noreferrer"
            >
              <span className="sr-only">{socialMedia.name}</span>
              <socialMedia.Icon className="text-black h-7 w-7" />
            </a>
          </li>
        ))}
      </ul>
    </Drawer>
  );
};
