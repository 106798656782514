export default {
  title: { en: "Jaw Line", he: "קו לסת" },
  paragraph1: {
    en: "Jaw line fillers treatment is a cosmetic procedure that uses injectable fillers to enhance the appearance of the jawline and define the face. The fillers used for this treatment are made of hyaluronic acid, a substance that occurs naturally in the body, and are designed to add volume and definition to the jawline, creating a more defined and youthful appearance. The treatment is non-invasive and can be completed in as little as 30 minutes.",
    he: "מילוי קו הלסת הוא טיפול אסתטי המשתמש בחומרי מילוי עוריים הניתנים להזרקה כדי לשפר ולהדגיש את מראה קו הלסת על ידי הוספת נפח.",
  },
  list: {
    title: {
      en: "There are two main types of fillers to choose from",
      he: "מילוי קו לסת מומלץ במקרים הבאים:",
    },
    items: [
      {
        en: "Hyaluronic acid fillers",
        he: "הוספת נפח והגדרה לקו לסת חלש או לא מוגדר באופן טבעי.",
      },
      {
        en: "Hyaluronic acid fillers",
        he: "תיקון אסימטריה מובנית של קו הלסת.",
      },
      {
        en: "Hyaluronic acid fillers",
        he: "שיפור את המראה של סנטר כפול.",
      },
      {
        en: "Hyaluronic acid fillers",
        he: "הדגשת קווי המתאר הכללי של הפנים וקבלת מראה צעיר יותר.",
      },
    ],
  },
  paragraph2: {
    en: "During the treatment, the practitioner will carefully inject the filler into specific areas of the jawline, using a fine needle. The filler is then smoothed and molded to create the desired shape and definition. The entire process is relatively painless, and most people only experience some mild discomfort and swelling, which should subside within a few days.",
    he: "חומרי המילוי המשמשים לשיפור קו הלסת עשויים בדרך כלל מחומצה היאלורונית, וההליך מבוצע על ידי רופא מוסמך ומנוסה. השפעות הטיפול נמשכות בדרך כלל 6-12 חודשים, ולאחר מכן ניתן לחזור על ההליך כדי לשמור על המראה הרצוי.",
  },
  paragraph3: {
    en: "One of the key benefits of jaw line fillers is that they provide instant results. Unlike traditional surgical procedures, there is no downtime, and patients can return to their normal activities right away. The results of the treatment can last anywhere from 6 to 12 months, depending on the type of filler used, and the individual's skin type, lifestyle, and age.",
    he: "מומלץ להתייעץ טרם ההליך עם הרופא המטפל כדי לדון בצרכים הספציפיים של כל מטופל ומטופלת. צרי קשר לייעוץ מקדים ללא תשלום.",
  },
};
