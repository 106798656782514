import { FieldError } from "react-hook-form";
import classNames from "classnames";
import { useLang } from "hooks/useLang";
import { getErrorMessage } from "lib/utils";

interface FormFieldProps {
  label: string;
  labelFor: string;
  labelOptional?: boolean;
  labelMax?: string;
  labelAria?: string;
  children: React.ReactNode;
  className?: string;
  error?: FieldError | FieldError[];
}

export const FormField: React.FC<FormFieldProps> = ({
  label,
  labelFor,
  labelOptional,
  labelMax,
  labelAria,
  children,
  className,
  error,
}) => {
  const { lang, isHebrew } = useLang();
  const errorMessage = error ? getErrorMessage(error, lang, label) : null;
  return (
    <div className={classNames(className, "relative  mb-4")}>
      <div
        className={classNames(
          "flex justify-between",
          isHebrew && "flex-row-reverse"
        )}
      >
        <label
          htmlFor={labelFor}
          className="block text-sm font-medium text-secondary"
        >
          {label}
        </label>
        {labelOptional && (
          <span id={labelAria} className="text-sm text-secondary">
            {labelMax}
          </span>
        )}
      </div>

      <div className="mt-1">{children}</div>
      <span
        className={classNames(
          "absolute -bottom-6 left-0 text-sm text-red-400",
          isHebrew && "right-0"
        )}
      >
        {errorMessage}
      </span>
    </div>
  );
};
