import { Translation } from "lib/types";
import content from "../content";

type NavItem = {
  name: Translation;
  href: string;
  children?: NavItem[];
};

type NavigationKeys =
  | "home"
  | "treatments"
  | "aboutMe"
  | "gallery"
  | "faq"
  | "contact";

type Navigation = { [key in NavigationKeys]: NavItem };

export const navigation: Navigation = {
  home: {
    name: { en: "Home", he: "בית" },
    href: "#home",
  },
  treatments: {
    name: { en: "Treatments", he: "טיפולים" },
    href: "#treatments",
    children: content.treatments.map((item) => ({
      name: { en: item.title.en, he: item.title.he },
      href: `/${item.id}`,
    })),
  },
  aboutMe: {
    name: { en: "About Me", he: "אודות" },
    href: "#aboutUs",
  },
  gallery: {
    name: { en: "Gallery", he: "גלריה" },
    href: "#gallery",
  },
  faq: {
    name: { en: "FAQ", he: "שאלות נפוצות" },
    href: "#faq",
  },
  contact: {
    name: { en: "Contact", he: "צור קשר" },
    href: "#contact",
  },
};
