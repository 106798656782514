import * as React from "react";
import { LandingPageAboutUsSection } from "pages/LandingPage/sections/LandingPageAboutUsSection";
import { LandingPageContactSection } from "./sections/LandingPageContactSection";
import { LandingPageFAQSection } from "./sections/LandingPageFAQSection";
import { LandingPageGallerySection } from "./sections/LandingPageGallerySection";
import { LandingPageHomeSection } from "./sections/LandingPageHomeSection";
import { LandingPageIncentivesSection } from "./sections/LandingPageIncentivesSection";
import { LandingPageTreatmentsSection } from "./sections/LandingPageTreatmentsSection";

export const LandingPage: React.FC = () => {
  return (
    <>
      <LandingPageHomeSection />
      <LandingPageTreatmentsSection />
      <LandingPageAboutUsSection />
      <LandingPageGallerySection />
      <LandingPageIncentivesSection />
      <LandingPageFAQSection />
      <LandingPageContactSection />
    </>
  );
};
