import { FieldError } from "react-hook-form";
import { Lang } from "./types";

export const getLanguage = (pathname: string): Lang => {
  return pathname.includes(Lang.en) ? Lang.en : Lang.he;
};

export const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -60;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

export const getErrorMessage = (
  error: FieldError | FieldError[],
  lang: Lang,
  fieldLabel?: string
): string => {
  if (!Array.isArray(error)) {
    if (error.message && error.message !== "") {
      return error.message;
    }

    if (error.type === "required") {
      if (lang === Lang.he) return ".שדה זה הינו חובה";
      return `${fieldLabel ? fieldLabel : "This field"} is required.`;
    }

    if (error.type === "maxLength") {
      if (lang === Lang.he) return ".שדה זה ארוך מדי";
      return `${fieldLabel ? fieldLabel : "This field"} is too long.`;
    }
  } else {
    return error.map((singleError) => singleError.message).join(", ");
  }

  return "Error";
};

export function importAll(r: __WebpackModuleApi.RequireContext) {
  const images: string[] = r.keys().map(r) as string[];
  return images.filter((item, index) => images.indexOf(item) === index);
}
