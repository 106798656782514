// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as WhatsAppIcon } from "assets/icons/whatsapp.svg";

export default {
  title: { en: "Menu", he: "תפריט" },
  treatments: [
    {
      id: 1,
      title: { en: "Botox injections", he: "בוטוקס" },
    },
    {
      id: 2,
      title: { en: "Jaw line", he: "קו לסת" },
    },
    {
      id: 3,
      title: { en: "Cheekbone", he: "עצמות לחיים" },
    },
    {
      id: 4,
      title: { en: "Lips augmentation", he: "מילוי שפתיים" },
    },

    {
      id: 5,
      title: { en: "Nasolabial folds", he: "מילוי קמטים" },
    },
    {
      id: 6,
      title: { en: "Excessive sweating", he: "הזעת יתר" },
    },
  ],
  socialMedia: [
    // {
    //   name: "facebook",
    //   url: "https://www.facebook.com/triada.mg",
    //   Icon: FacebookIcon
    // },
    {
      name: "instagram",
      url: "https://www.instagram.com/dr.maayan",
      Icon: InstagramIcon,
    },
    {
      name: "whatsapp",
      url: "https://wa.me/972503543940",
      Icon: WhatsAppIcon,
    },
  ],
};
