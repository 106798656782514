export const AppRoutePaths = {
  homeHE: { url: "/he/", name: "Home" },
  homeEN: { url: "/en/", name: "Home" },
  treatmentDetailsHE: {
    url: "/he/treatments/:treatmentId",
    name: "Treatment details",
  },
  treatmentDetailsEN: {
    url: "/en/treatments/:treatmentId",
    name: "Treatment details",
  },
  notFound: { url: "*", name: "Page not found" },
};
