export type Langs = "en" | "he";

export enum Lang {
  en = "en",
  he = "he",
}

export type Translation = { [key in Langs]: string };

export interface FAQ {
  id: number;
  question: Translation;
  answer: Translation;
}
