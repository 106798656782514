import * as React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { TreatmentBotoxPage } from "./TreatmentPages/TreatmentBotoxPage";
import { TreatmentCheekbonesPage } from "./TreatmentPages/TreatmentCheekbonesPage";
import { TreatmentExcessiveSweatingPage } from "./TreatmentPages/TreatmentExcessiveSweatingPage";
import { TreatmentJawLinePage } from "./TreatmentPages/TreatmentJawLinePage";
import { TreatmentLipAugmentationPage } from "./TreatmentPages/TreatmentLipAugmentationPage";
import { TreatmentNasolabialFoldsPage } from "./TreatmentPages/TreatmentNasolabialFoldsPage";
import { ContactBanner } from "./ui/ContactBanner";

export const TreatmentDetailsPage: React.FunctionComponent = () => {
  const { treatmentId } = useParams();

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <section className={classNames("section w-screen pb-8 bg-light")}>
      <div
        className={classNames(
          "px-6 lg:px-12 max-w-7xl mx-auto flex flex-col md:gap-2 pt-24 pb-10 md:pb-4 md:min-h-[calc(100vh+3rem)] w-full"
        )}
      >
        {(() => {
          switch (Number(treatmentId)) {
            case 1:
              return <TreatmentBotoxPage />;
            case 2:
              return <TreatmentJawLinePage />;
            case 3:
              return <TreatmentCheekbonesPage />;
            case 4:
              return <TreatmentLipAugmentationPage />;
            case 5:
              return <TreatmentNasolabialFoldsPage />;
            case 6:
              return <TreatmentExcessiveSweatingPage />;
            default:
              <></>;
          }
        })()}
      </div>
      <ContactBanner />
    </section>
  );
};
