import { Button } from "components/ui/Button";
import { useLang } from "hooks/useLang";
import content from "../content";

export const ImageContainer: React.FunctionComponent<{
  src: string;
  onClick: () => void;
}> = ({ src, onClick }) => {
  const { lang } = useLang();

  return (
    <div
      onClick={onClick}
      className="bg-purple w-full h-48 rounded-lg flex justify-center content-center overflow-hidden h-48"
    >
      <div
        className="relative group cursor-pointer"
        style={{
          backgroundImage: `url(${src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="hidden group-hover:flex absolute bg-black bg-opacity-50 w-full h-full items-center justify-center">
          <Button variant="label-white" className="text-white hover:text-white">
            {content.preview[lang]}
          </Button>
        </div>
      </div>
    </div>
  );
};
