export default {
  title: { en: "Lips augmentation", he: "מילוי שפתיים" },
  paragraph1: {
    en: "Lips augmentation is a cosmetic procedure that involves adding volume to the lips to enhance their appearance. It is commonly performed using injectable fillers made of hyaluronic acid, a substance that occurs naturally in the body. The treatment is non-invasive and can be completed in as little as 30 minutes.",
    he: "שפתיים בשרניות ויפות היו מאז ומתמיד סימן ליופי נשי וחושניות. מילוי שפתיים הוא הליך אסתטי מהמבוקשים ביותר בעולם היופי הכולל הוספת נפח והגדרה לשפתיים כדי לשפר את המראה שלהן.",
  },
  paragraph2: {
    en: "Lips augmentation is a cosmetic procedure that involves adding volume to the lips to enhance their appearance. It is commonly performed using injectable fillers made of hyaluronic acid, a substance that occurs naturally in the body. The treatment is non-invasive and can be completed in as little as 30 minutes.",
    he: 'הטיפול נעשה לרב ע"י הזרקת חומצה היאלורונית- חומר טבעי שנמצא בגוף האדם. חומצה זו מהווה שלד במגוון רקמות ויכולתה לספוח מים בכמות גדולה מאפשרת להעניק נפח וחוזק.',
  },
  paragraph3: {
    en: "During the treatment, the practitioner will carefully inject the filler into the lips, using a fine needle. The filler is then smoothed and molded to create the desired shape and fullness. The entire process is relatively painless, and most people only experience some mild discomfort and swelling, which should subside within a few days.",
    he: "התוצאות יכולות להימשך 6-12 חודשים, ולאחר מכן ייתכן שיהיה צורך לחזור על ההליך כדי לשמור על המראה הרצוי.",
  },
  list: {
    title: {
      en: "There are two main types of fillers to choose from",
      he: "מילוי שפתיים מתאים במקרים הבאים:",
    },
    items: [
      {
        en: "Hyaluronic acid fillers",
        he: "עיבוי שפתיים דקות או קטנות.",
      },
      {
        en: "Hyaluronic acid fillers",
        he: "שיפור ותיקון אי סימטריות של שפתיים לא אחידות.",
      },
      {
        en: "Hyaluronic acid fillers",
        he: "שיפור הגדרת גבולות וצורת השפתיים.",
      },
      {
        en: "Hyaluronic acid fillers",
        he: "החזרת נפח לשפתיים שהתדלדלו עם הגיל.",
      },
      {
        en: "Hyaluronic acid fillers",
        he: "יצירת מראה שפתיים צעיר יותר.",
      },
    ],
  },
  paragraph4: {
    en: "One of the key benefits of lip augmentation is that it provides instant results. The lips will look fuller and more defined immediately after the treatment, and the results can last anywhere from 6 to 12 months, depending on the type of filler used, and the individual's skin type, lifestyle, and age. The treatment can also be repeated as needed to maintain the desired level of lip fullness.",
    he: "מילוי שפתיים נחשב לדרך בטוחה ויעילה לשיפור מראה השפתיים. עם זאת, כמו בכל הליך קוסמטי, ישנם סיכונים פוטנציאליים ותופעות לוואי, כולל נפיחות, חבורות וזיהום. חשוב לבצע את הטיפול אך ורק אצל רופא מוסמך. צרי קשר לקבלת ייעוץ חינם וללא התחייבות.",
  },
};
