import { Fragment } from "react";
import classNames from "classnames";
import { SectionWrapper } from "components/Section";
import { useLang } from "hooks/useLang";
import { ContactForm } from "pages/LandingPage/sections/LandingPageContactSection/ui/ContactForm";
import content from "./content";

export const LandingPageContactSection: React.FunctionComponent = () => {
  const { lang, isHebrew } = useLang();

  return (
    <SectionWrapper id="contact" title={content.title[lang]} color="white">
      <div
        className={classNames(
          "grid grid-cols-1 lg:grid-cols-3",
          isHebrew && "text-right"
        )}
      >
        {/* Contact information */}
        <div className="prose prose-md relative overflow-hidden md:py-10 lg:pr-8 pb-4 border-b lg:border-none">
          <h3 className="text-secondary">{content.subtitle1[lang]}</h3>
          <p
            dir={isHebrew ? "rtl" : "ltr"}
            className={classNames("text-secondary", isHebrew && "text-right")}
          >
            {content.description[lang]}
          </p>
          <dl className="space-y-6">
            {content.contactDetails.map((item) => (
              <Fragment key={item.name}>
                <dt>
                  <span className="sr-only">{content.phone[lang]}</span>
                </dt>
                <dd
                  className={classNames(
                    "flex gap-2 justify-end items-center",
                    isHebrew ? "text-right" : "flex-row-reverse"
                  )}
                >
                  <a
                    href={item.prefix + ":" + item.value}
                    className="ml-3 text-secondary no-underline"
                  >
                    {item.value}
                  </a>
                  <item.Icon
                    className="h-6 w-6 flex-shrink-0 text-primary"
                    aria-hidden="true"
                  />
                </dd>
              </Fragment>
            ))}
          </dl>
          <ul
            className={classNames(
              "flex space-x-12 list-none pl-0 mt-6",
              isHebrew && "justify-end"
            )}
          >
            {content.socialMedia.map((socialMedia) => (
              <li key={socialMedia.name} className="pl-0">
                <a
                  className="text-primary hover:text-primary"
                  target="_blank"
                  href={socialMedia.url}
                  rel="noreferrer"
                >
                  <span className="sr-only">{socialMedia.name}</span>
                  <socialMedia.Icon className="h-7 w-7" />
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Contact form */}
        <div className="prose prose-md pt-4 md:py-10 lg:col-span-2 lg:pl-8 lg:border-l lg:border-white">
          <h3 className="text-secondary">{content.subtitle2[lang]}</h3>
          <ContactForm />
        </div>
      </div>
    </SectionWrapper>
  );
};
