import { HashLink } from "react-router-hash-link";
import classNames from "classnames";
import { useLang } from "hooks/useLang";
import { scrollWithOffset } from "lib/utils";

export const ContactBanner: React.FC = () => {
  const { lang, isHebrew } = useLang();
  return (
    <>
      <div className="fixed inset-x-0 bottom-0">
        <div
          className={classNames(
            "flex items-center justify-center gap-2 bg-black py-4 px-6 text-white text-sm",
            isHebrew && "flex-row-reverse"
          )}
        >
          <strong className="font-semibold">Luna Clinic</strong>
          <svg
            viewBox="0 0 2 2"
            className="inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          <HashLink
            smooth
            to={`/${lang}/#contact`}
            scroll={(el) => scrollWithOffset(el)}
            className={classNames(
              "flex items-center gap-2",
              isHebrew && "flex-row-reverse"
            )}
          >
            {isHebrew ? (
              <span>
                <span aria-hidden="true">&larr;</span> צור קשר{" "}
                <span className="text-primary">עכשיו</span> כדי לקבל ייעוץ חינם
              </span>
            ) : (
              <span>
                Contact us <span className="text-primary">now</span> to receive
                a free consultation <span aria-hidden="true">&rarr;</span>
              </span>
            )}
          </HashLink>
        </div>
      </div>
    </>
  );
};
