import { useRef } from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { SectionWrapper } from "components/Section";
import { Button } from "components/ui/Button";
import { Card } from "components/ui/Card";
import { useLang } from "hooks/useLang";
import content from "./content";

export const LandingPageTreatmentsSection: React.FunctionComponent = () => {
  const { lang } = useLang();
  const sliderRef = useRef<Slider>(null);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    arrows: false,
    autoplay: true,
  };
  return (
    <SectionWrapper
      id="treatments"
      title={content.title[lang]}
      fullHeight={false}
    >
      <div className="xs:block md:hidden">
        <Slider ref={sliderRef} {...settings}>
          {content.items.map((treatment) => (
            <NavLink
              key={treatment.id}
              to={`/${lang}/treatments/${treatment.id}`}
            >
              <Card title={treatment.title[lang]} image={treatment.imageUrl} />
            </NavLink>
          ))}
        </Slider>
        <div className="flex justify-between mt-4">
          <Button
            variant="label"
            onClick={() => sliderRef.current?.slickPrev()}
          >
            <ArrowLeftIcon className="w-8 h-8 text-black hover:text-primary-dark " />
          </Button>
          <Button
            variant="label"
            onClick={() => sliderRef.current?.slickNext()}
          >
            <ArrowRightIcon className="w-8 h-8 text-black hover:text-primary-dark " />
          </Button>
        </div>
      </div>
      <ul className="prose prose-md hidden space-y-12 md:grid md:grid-cols-2 md:gap-x-6 md:gap-y-12 md:space-y-0 lg:grid-cols-3 lg:gap-x-8 mb-6">
        {content.items.map((treatment) => (
          <li key={treatment.id} className="card">
            <NavLink
              key={treatment.id}
              to={`/${lang}/treatments/${treatment.id}`}
              className="no-underline"
            >
              <Card title={treatment.title[lang]} image={treatment.imageUrl} />
            </NavLink>
          </li>
        ))}
      </ul>
    </SectionWrapper>
  );
};
