export default {
  title: { en: "Gallery", he: "גלריה" },
  description: {
    en: "Our photo gallery showcases the incredible results that our patients have achieved through our wide range of aesthetic medicine treatments. From non-invasive options like Botox and fillers, to more extensive procedures like facelifts and body contouring, you can see the real-life transformations that our patients have undergone. Browse our gallery to get a sense of the possibilities and find inspiration for your own journey towards enhanced beauty and confidence.",
    he: "גלריית התמונות שלנו מציגה את התוצאות המדהימות של החולים שלנו שהגיעו להם באמצעות טיפולים רחבי הפנים של רפואת האסתטיקה שלנו. מאפשרים לא פנימיים כמו בוטוקס ומלאנים, עד תהליכים יותר מפורטים כמו פסיכופטיה וקונטורינג גוף, אתה יכול לראות את ההמרות האמיתיות שהחולים שלנו עברו. גלה את הגלריה כדי לקבל חוויה מהפוטנציאלים ולמצוא השראה למסעך העצמי כיוון אל יופי ואמון משופרים.",
  },
  show: { en: "Show", he: "הצג" },
  more: { en: "more", he: "עוד" },
  less: { en: "less", he: "פחות" },
  preview: { en: "Preview", he: "תצוגה מקדימה" },
};
