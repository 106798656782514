import * as React from "react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./ui/Header";

export const Layout: React.FunctionComponent = () => {
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    );
  }, []);

  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
    </>
  );
};
