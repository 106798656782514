import { useCallback, useMemo, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { SectionWrapper } from "components/Section";
import { useLang } from "hooks/useLang";
import { importAll } from "lib/utils";
import { ImageContainer } from "./ui/ImageContainer";
import content from "./content";

export const LandingPageGallerySection: React.FunctionComponent = () => {
  const { lang } = useLang();
  const [showAll, setShowAll] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = useMemo(
    () =>
      importAll(
        require.context("./images", false, /\.(png|jpe?g|svg)$/)
      ) as string[],
    []
  );

  const imagesToShow = 6;

  const defaultImages = useMemo(() => images.slice(0, imagesToShow), [images]);
  const all = useMemo(() => images.slice(imagesToShow), [images]);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <SectionWrapper id="gallery" title={content.title[lang]}>
      <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {defaultImages.map((src, index) => (
          <ImageContainer
            key={src}
            src={src}
            onClick={() => openImageViewer(index)}
          />
        ))}
        {showAll &&
          all.map((src, index) => (
            <ImageContainer
              key={src}
              src={src}
              onClick={() => openImageViewer(index)}
            />
          ))}

        <div className="z-[9999]">
          {isViewerOpen && (
            <ImageViewer
              src={images}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )}
        </div>
      </div>
      {images.length > imagesToShow && (
        <div
          className="flex justify-center items-center gap-2 hover:underline cursor-pointer hover:text-primary"
          onClick={() => setShowAll(!showAll)}
        >
          <span>
            {content.show[lang]}{" "}
            {showAll ? content.less[lang] : content.more[lang]}
          </span>
          {showAll ? (
            <ChevronUpIcon className="h-5 w-5" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" />
          )}
        </div>
      )}
    </SectionWrapper>
  );
};
