import { useCallback, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import classNames from "classnames";
import { init, send } from "emailjs-com";
import { useLang } from "hooks/useLang";
import { FormField } from "../../../../../components/form/FormField";
import { TextArea } from "../../../../../components/form/TextArea";
import { TextInput } from "../../../../../components/form/TextInput";
import { Button } from "../../../../../components/ui/Button";
import { Spinner } from "../../../../../components/ui/Spinner";
import content from "../content";

export interface ContactForm {
  fullName: string;
  email: string;
  phone?: string;
  message: string;
}

export const ContactForm: React.FC = () => {
  const { lang, isHebrew } = useLang();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ContactForm>({
    mode: "onSubmit",
  });

  const onSubmit = useCallback(
    async ({ fullName, message, email, phone }: ContactForm) => {
      const serviceId = process.env.REACT_APP_EMAIL_SERVICE_ID;
      const templateId = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
      const userId = process.env.REACT_APP_EMAIL_USER_ID;

      if (serviceId && templateId && userId) {
        setLoading(true);
        try {
          init(userId);
          await send(serviceId, templateId, {
            from_name: fullName,
            message,
            phone,
            reply_to: email,
          });
          toast.success(content.form.success[lang], {
            className: "skip-fade",
          });
        } catch (err) {
          toast.error(content.form.error[lang], {
            className: "skip-fade",
          });
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    },
    []
  );
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
    >
      <FormField
        label={content.form.fullName[lang]}
        labelFor="fullName"
        className="sm:col-span-2"
        error={errors.fullName as FieldError}
      >
        <TextInput
          id="fullName"
          {...register("fullName", { required: true })}
        />
      </FormField>
      <FormField
        label={content.form.email[lang]}
        labelFor="email"
        error={errors.email as FieldError}
      >
        <TextInput
          id="email"
          type="email"
          {...register("email", { required: true })}
        />
      </FormField>
      <FormField
        label={content.form.phone[lang]}
        labelFor="phone"
        labelOptional
        labelMax={content.form.optional[lang]}
        labelAria="phone-optional"
      >
        <TextInput id="phone" {...register("phone")} />
      </FormField>
      <FormField
        label={content.form.message[lang]}
        labelFor="message"
        className="sm:col-span-2"
        labelMax={content.form.optional[lang]}
        labelAria="message-max"
        error={errors.message as FieldError}
      >
        <TextArea
          id="message"
          rows={5}
          aria-describedby="message-max"
          {...register("message", { required: true, maxLength: 500 })}
        />
      </FormField>
      <div
        className={classNames(
          "w-full md:w-max md:col-span-2 md:w-full md:flex",
          isHebrew ? "md:justify-start" : "md:justify-end"
        )}
      >
        <Button
          type="submit"
          className="w-24 flex items-center justify-center"
          disabled={isSubmitting}
        >
          {loading ? <Spinner /> : content.form.send[lang]}
        </Button>
      </div>
    </form>
  );
};
