export default {
  title: { en: "Excessive sweating", he: "הזעת יתר" },
  paragraph1: {
    en: "Botox treatment for excessive sweating, also known as hyperhidrosis, is a medical procedure that uses botulinum toxin type A to temporarily paralyze the sweat glands and reduce excessive sweating. Botox is a safe and effective treatment option for people who suffer from hyperhidrosis and are looking for a long-term solution to control excessive sweating.",
    he: "הזעת יתר או בשמה הרפואי היפרהידרוסיס הינה תופעה נפוצה מאוד בקרב מבוגרים הגורמת לאי נעימות  ומבוכה , ריח גוף לא נעים ,השפעה שלילית על הביטחון האישי ואף לעתים להימנעות מפעולות בסיסיות. האזור הנפוץ ביותר בו ישנה הזעת יתר הוא אזור בית השחי היוצר את העיגולים המפורסמים מתחת לבית השחי.",
  },
  paragraph2: {
    en: "The treatment is performed by injecting tiny amounts of Botox into the affected areas, such as the underarms, palms, or soles of the feet. The procedure takes about 15-30 minutes and is usually performed on an outpatient basis. The Botox works by blocking the release of acetylcholine, a neurotransmitter that stimulates the sweat glands, and as a result, reducing the amount of sweat produced.",
    he: 'לשמחתנו כיום נעשה שימוש יעיל ובטוח במיוחד בבוטוליניום במיוחד בהזעת יתר. התהליך מהיר וכולל הזרקה ע"י רופא מנוסה ומיומן באזור הזעת היתר, על מנת לחסום באופן זמני את העצבים באזור המפעילים את בלוטות הזיעה.',
  },
  paragraph3: {
    en: "One of the key benefits of Botox for excessive sweating is that it provides long-lasting results. The effects of the treatment can last anywhere from 6 to 12 months, and the treatment can be repeated as needed to maintain the desired level of sweat reduction. The procedure is also relatively painless, and most people only experience some mild discomfort and swelling, which should subside within a few days.",
    he: "לאחר הטיפול ישנה הפחתה של לפחות 80% בהזעה והתופעות השליליות הנלוות.",
  },
  paragraph4: {
    en: "Finally, it is important to keep in mind that Botox for excessive sweating is not a cure for hyperhidrosis, but rather a temporary solution to manage the condition. The patient should continue to practice good hygiene and wear breathable clothing to minimize sweating, and should inform the practitioner of any changes in their medical history, as this may affect the results of the treatment.",
    he: "בניגוד לניתוח או טיפולים אחרים חשוב להדגיש כי טיפול בהזעת יתר בבוטליניום אינו יוצר הזעה מפצה באזורים אחרים בגוף. הטיפול מחזיק בערך כ4-5 חודשים(משתנה בין אדם לאדם) ובעל פרופיל בטיחותי גבוה.",
  },
  paragraph5: {
    en: "Finally, it is important to keep in mind that Botox for excessive sweating is not a cure for hyperhidrosis, but rather a temporary solution to manage the condition. The patient should continue to practice good hygiene and wear breathable clothing to minimize sweating, and should inform the practitioner of any changes in their medical history, as this may affect the results of the treatment.",
    he: "קבעו פגישת ייעוץ חינם וללא התחייבות.",
  },
};
