import { ReactComponent as CellPhoneIcon } from "./icons/CellPhoneIcon.svg";
import { ReactComponent as ConsultationIcon } from "./icons/ConsultationIcon.svg";
import { ReactComponent as ResultIcon } from "./icons/ResultIcon.svg";

export default {
  title: {
    en: "3 easy steps to get your solution",
    he: "3 שלבים פשוטים להראות טוב מתמיד",
  },
  items: [
    {
      Icon: CellPhoneIcon,
      title: { en: "Contact us", he: "צרו קשר" },
      description: {
        en: "Let's start your aesthetic journey together. Schedule your consultation with our expert team today.",
        he: "וקבעו פגישת ייעוץ ללא תשלום וללא התחייבות",
      },
    },
    {
      Icon: ConsultationIcon,
      title: { en: "Receive your consultation", he: "קבלו ייעוץ מקצועי חינם" },
      description: {
        en: "Tailored aesthetic solutions. Discuss goals and determine the best treatment plan.",
        he: "קבלו הצעה לפתרונות ותכנית טיפול מותאמת אישית",
      },
    },
    {
      Icon: ResultIcon,
      title: {
        en: "Get the desired result",
        he: "השיגו את התוצאה שתמיד רציתם",
      },
      description: {
        en: "Achieving your aesthetic goals with our expert care. Trust us to help you achieve the look you desire.",
        he: "שלב הטיפול שתוכנן מראש ומבוצע בקפידה ומקצועיות",
      },
    },
  ],
};
