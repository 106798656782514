import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Layout } from "components/layout/Layout";
import { LandingPage } from "pages/LandingPage";
import { PageNotFound } from "pages/NotFoundPage";
import { TreatmentDetailsPage } from "pages/TreatmentDetailsPage";
import { browserHistory } from "routing/browserHistory";
import { HistoryRouter } from "routing/HistoryRouter";
import { LanguageRedirectRoute } from "routing/RedirectRoute";
import { AppRoutePaths } from "routing/routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="colored"
      />
      <HistoryRouter history={browserHistory}>
        <Routes>
          <Route element={<LanguageRedirectRoute />}>
            <Route element={<Layout />}>
              <Route
                path={AppRoutePaths.homeHE.url}
                element={<LandingPage />}
              />
              <Route
                path={AppRoutePaths.homeEN.url}
                element={<LandingPage />}
              />
              <Route
                path={AppRoutePaths.treatmentDetailsHE.url}
                element={<TreatmentDetailsPage />}
              />
              <Route
                path={AppRoutePaths.treatmentDetailsEN.url}
                element={<TreatmentDetailsPage />}
              />
            </Route>
            <Route
              path={AppRoutePaths.notFound.url}
              element={<PageNotFound />}
            />
          </Route>
        </Routes>
      </HistoryRouter>
    </div>
  );
}

export default App;
