import React from "react";
import classNames from "classnames";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ className, type = "text", ...inputProps }, ref) => {
    const classes = classNames(
      "h-10 block w-full rounded-md border border-secondary py-3 px-4 text-black shadow-sm focus:border-primary focus:ring-primary",
      className
    );

    return <input ref={ref} className={classes} type={type} {...inputProps} />;
  }
);
