import classNames from "classnames";
import { SectionWrapper } from "components/Section";
import { useLang } from "hooks/useLang";
import me from "./images/me.jpg";
import content from "./content";

export const LandingPageAboutUsSection: React.FunctionComponent = () => {
  const { lang, isHebrew } = useLang();
  return (
    <SectionWrapper
      id="aboutUs"
      title={content.title[lang]}
      background="bg-[#d7b89c] bg-opacity-50"
    >
      <div
        className={classNames(
          "flex gap-4 flex-col-reverse md:flex-row",
          isHebrew && "text-right md:flex-row-reverse"
        )}
      >
        <article
          dir={isHebrew ? "rtl" : "ltr"}
          className={classNames(
            "prose prose-md basis-2/3 mt-4 md:mt-0",
            isHebrew ? "md:ml-24" : "md:mr-24"
          )}
        >
          <h2>{content.name[lang]}</h2>
          <p>{content.paragraph[lang]}</p>
          <div className="mx-auto mt-5 lg:col-start-1 lg:row-start-1 lg:max-w-none">
            <ul
              className={classNames(
                "list-disc marker:text-primary",
                isHebrew && "mr-4"
              )}
            >
              {content.bulletsPoints.map((item, index) => (
                <li key={index}>{item[lang]}</li>
              ))}
            </ul>
          </div>
        </article>
        <div className="basis-1/3">
          <figure>
            <div className="relative aspect-w-12 aspect-h-6 lg:aspect-none">
              <svg
                className={classNames(
                  "absolute top-[10%] right-0 z-[-1] w-[404px] lg:w-[450px]",
                  isHebrew && "md:left-0"
                )}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-primary"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <img
                className={classNames(
                  "rounded-md object-cover object-center shadow-lg w-[400px] lg:w-[350px]",
                  isHebrew ? "md:mr-32" : ""
                )}
                src={me}
                alt="Whitney leaning against a railing on a downtown street"
              />
            </div>
          </figure>
        </div>
      </div>
    </SectionWrapper>
  );
};
