import * as React from "react";
import { NavLink, useParams } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { ReactComponent as LanguageIcon } from "assets/icons/language.svg";
import logo from "assets/images/logo.png";
import { Button } from "components/ui/Button";
import { useLang } from "hooks/useLang";
import { Lang } from "lib/types";
import { AppRoutePaths } from "routing/routes";
import { MobileMenu } from "./MobileMenu";
import { NavigationMenu } from "./NavigationMenu";

const Header = () => {
  const [open, setOpen] = React.useState(false);

  const { treatmentId } = useParams();
  const { isHebrew, isEnglish } = useLang();

  return (
    <header className="fixed h-20 w-screen top-0 z-20 bg-black py-2">
      <nav className="max-w-7xl mx-auto px-6 lg:px-12 flex justify-between items-center">
        <NavLink
          to={
            (isHebrew ? AppRoutePaths.homeHE.url : AppRoutePaths.homeEN.url) +
            "#home"
          }
        >
          <img src={logo} className="h-16" />
        </NavLink>
        <div className="flex gap-4 items-center pt-2 pb-4">
          <NavigationMenu />
          <div className="flex gap-1 text-secondary items-center">
            <LanguageIcon className="h-5 w-5 text-secondary" />
            <a
              href={
                `/${Lang.en}` +
                (treatmentId ? `/treatments/${treatmentId}` : "")
              }
              className={isEnglish ? "text-primary" : ""}
            >
              {Lang.en}
            </a>
            |
            <a
              href={
                `/${Lang.he}` +
                (treatmentId ? `/treatments/${treatmentId}` : "")
              }
              className={isHebrew ? "text-primary" : ""}
            >
              {Lang.he}
            </a>
          </div>
          <Button
            variant="outline"
            size="small"
            onClick={() => setOpen(true)}
            className="md:hidden"
          >
            <MenuOutlined />
          </Button>
          <MobileMenu open={open} setOpen={setOpen} />
        </div>
      </nav>
    </header>
  );
};

export default Header;
