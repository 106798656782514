export default {
  title: { en: "Nasolabial folds", he: "מילוי קמטים" },
  paragraph1: {
    en: "Nasolabial folds are the wrinkles that run from the sides of the nose to the corners of the mouth. They are a natural part of the aging process, but they can also be exacerbated by factors such as sun exposure, smoking, and repeated facial movements.",
    he: "ישנן דרכים טבעיות ובריאות לעכב את תהליך ההזדקנות  - מניעה מחשיפה לשמש , מניעה מעישון , תזונה בריאה, קרם הגנה , אורח חיים בריא ועוד. לצערנו גם אם נקיים את את כל צעדי מניעה שהזכרנו תהליך ההזדקנות לא ניתן לעצירה, לפחות לא עדיין.",
  },
  paragraph2: {
    en: "Nasolabial fold treatment involves using injectable fillers to add volume to the area and smooth out the wrinkles. The fillers used for this treatment are made of hyaluronic acid, a substance that occurs naturally in the body, or other materials that are designed to restore lost volume and smooth out wrinkles. The treatment is non-invasive and can be completed in as little as 30 minutes.",
    he: "במהלך השנים פנינו עוברים שינויים רבים : ישנה ירידה באלסטיות העור, רקמות שונות מדלדלות , צפיפות העצם פוחתת והתחדשות התאים פוחתת. כל זאת משנה את משנה את מראה ומבנה הפנים, גורם לצניחת הפנים והופעת קמטים.",
  },

  paragraph3: {
    en: "It is important to choose a qualified and experienced practitioner for nasolabial fold treatment. This will ensure that the treatment is performed safely and effectively, and that the results meet the patient's expectations. The practitioner should have extensive training and experience in facial anatomy and cosmetic procedures, and should be able to provide detailed information about the potential risks and benefits of the treatment. It is also important to choose a practitioner who has a good eye for aesthetics and who can create a natural-looking, harmonious result.",
    he: "החדשות הטובות הן שיש דרכים רבות ויעילות להאט את התהליך, ומעבר לכך להשיב לעור שלך את שאבד במשך השנים באופן טבעי לקבלת מראה מטופח וצעיר יותר.",
  },
  list: {
    title: {
      en: "There are two main types of fillers to choose from",
      he: "ראשית יש להבחין בין 2 סוגי קמטים עיקריים:",
    },
    items: [
      {
        en: "Hyaluronic acid fillers",
        he: 'קמטים פונקציונאליים(קמטי הבעה):  קמטים אלה מופיעים בין היתר באזור המצח, בין הגבות -"קמטי הדאגה", בצידי העיניים-"רגלי העורב" , בצידי האף והפה - "קמטי החיוך"(הנזולביאל) , מסביב לשפתיים -קמטי ה"ברקוד" ועוד.  קמטים פונקציונאליים נוצרים בשל הבעת הפנים שלנו וניתנים למניעה ע"י הזרקת בוטוקס. קמטים פונקציונאליים עלולים להתפתח לקמטים סטטים עם הזמן.',
      },
      {
        en: "Hyaluronic acid fillers",
        he: 'קמטים סטטים- קמטים אלה נוצרים במהלך השנים באזור הפנים והגוף כתוצאה מאיבוד האלסטיות בעור (ירידה בקולגן ואלסטין) וכתוצאה מהתדלדלות הרקמות ונפילתן מה שמוביל לקפלים וקמטים. בניגוד לקמטים דינמיים , קמטים סטטים מופיעים גם במנוחה. תחת קבוצה זו של קמטים אפשר לציין את קמטי החיוך שנוצרים מקיפול כתוצאה של נפילת רקמות השומן בלחיים עם השנים ואת קמטי "המריונטה" בצידי הפה.',
      },
    ],
  },
  paragraph4: {
    en: "",
    he: 'לכל קמט וכל אזור בפנים ובגוף יש דרך יעילה ונכונה לטיפול , החל מטיפול בעזרת חומצה היאלורונית המותאמת לאזור ולקמט, מתיחת פנים ללא ניתוח ע"י חוטים נספגים או מכשור חדיש, סקין בוסטר(חומצה היאלורונית במרקם  מיוחד) , לייזר ועוד.',
  },
  paragraph5: {
    en: "",
    he: "אשמח לתת מענה וייעוץ מקדים ללא עלות על מנת להשיג את התוצאה הטובה ביותר, בדגש על שימוש בחומרים האיכותיים ביותר וללא טיפולים מיותרים והוצאות מיותרות. את מוזמנת ליצור קשר לייעוץ חינם.",
  },
};
