import { HashLink } from "react-router-hash-link";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import main from "assets/images/main.jpeg";
import classNames from "classnames";
import { useLang } from "hooks/useLang";
import { scrollWithOffset } from "lib/utils";
import content from "./content";

export const LandingPageHomeSection: React.FunctionComponent = () => {
  const { lang, isHebrew, isEnglish } = useLang();

  return (
    <section id="home" className="section bg-light">
      <div className="md:px-6 md:pt-6 lg:px-12 max-w-7xl mx-auto flex flex-col md:gap-2 fullHeight md:h-screen pt-20">
        <div
          className={classNames(
            "hidden md:flex h-full items-center justify-start",
            isHebrew && "text-right"
          )}
        >
          <div className="md:w-3/5 prose lg:prose-lg px-6 md:w-2/3 lg:w-1/2 xl:pr-16">
            <h1>
              <span className="block xl:inline">{content.heading1[lang]} </span>
              <span className="block text-primary xl:inline">
                {content.heading2[lang]}
              </span>
            </h1>
            <p dir={isHebrew ? "rtl" : "ltr"}>{content.description[lang]}</p>
            <div
              className={classNames(
                "mt-10 sm:flex sm:justify-center",
                isEnglish ? "md:justify-start" : "md:justify-end"
              )}
            >
              <div className="rounded-md shadow">
                <HashLink
                  smooth
                  to={"#treatments"}
                  scroll={(el) => scrollWithOffset(el)}
                  className={classNames(
                    "no-underline text-white hover:text-white bg-primary hover:bg-primary-dark font-bold px-4 rounded flex items-center justify-center gap-2 py-4 ",
                    isHebrew && "flex-row-reverse"
                  )}
                >
                  {content.detailsBtn[lang]}
                  {isEnglish ? (
                    <ArrowRightIcon className="h-5 w-5 text-white" />
                  ) : (
                    <ArrowLeftIcon className="h-5 w-5 text-white" />
                  )}
                </HashLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            "h-64 w-full h-full md:absolute md:inset-y-0 md:right-0 md:h-full md:w-1/3 lg:w-1/2",
            isHebrew && "text-right"
          )}
        >
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={main}
            alt="main"
          />
          <div className="prose prose-md md:hidden absolute bottom-4 w-full h-92 bg-black bg-opacity-70 p-6">
            <h1>
              <span className="block text-secondary">
                {content.heading1[lang]}
              </span>
              <span className="block text-primary">
                {content.heading2[lang]}
              </span>
            </h1>
            <p dir={isHebrew ? "rtl" : "ltr"} className="mt-3 text-secondary">
              {content.description[lang]}
            </p>
            <div className="mt-10 sm:flex sm:justify-center md:justify-end">
              <div className="rounded-md shadow">
                <HashLink
                  smooth
                  to={"#treatments"}
                  scroll={(el) => scrollWithOffset(el)}
                  className={classNames(
                    "no-underline bg-primary hover:bg-primary-dark text-secondary font-bold px-4 rounded flex items-center justify-center gap-2 py-4",
                    isHebrew ? "flex-row-reverse" : "flex-row"
                  )}
                >
                  {content.detailsBtn[lang]}
                  {isEnglish ? (
                    <ArrowRightIcon className="h-5 w-5" />
                  ) : (
                    <ArrowLeftIcon className="h-5 w-5" />
                  )}
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
