import * as React from "react";
import { Button } from "components/ui/Button";
import { AppRoutePaths } from "routing/routes";

export const PageNotFound: React.FunctionComponent = () => {
  return (
    <div className="flex h-screen items-center min-h-full bg-white py-16 px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-bold tracking-tight text-primary sm:text-5xl">
            404
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Page not found
              </h1>
              <p className="mt-1 text-base text-gray-700">
                Please check the URL in the address bar and try again.
              </p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Button url={AppRoutePaths.homeEN.url}>Go back home</Button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
