import { useEffect, useMemo, useState } from "react";
import { SectionWrapper } from "components/Section";
import { useLang } from "hooks/useLang";
import content from "./content";

export const LandingPageIncentivesSection: React.FunctionComponent = () => {
  const { lang, isHebrew } = useLang();
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const items = useMemo(
    () =>
      isHebrew && !isMobile ? [...content.items].reverse() : content.items,
    [isHebrew]
  );

  return (
    <SectionWrapper
      id="incentives"
      fullHeight={false}
      background="bg-[#d7b89c] bg-opacity-50"
    >
      <div className="prose prose-md">
        <h2 dir={isHebrew ? "rtl" : "ltr"} className="text-center">
          {content.title[lang]}
        </h2>
        <div className="mx-auto w-8/12 md:w-3/12 border-b-2 border-primary mb-6 mt-2" />
        <div className="grid md:grid-cols-3 gap-8">
          {items.map((item) => (
            <div
              key={item.title.en}
              className="flex flex-col items-center justify-center"
            >
              <div className="bg-black bg-opacity-90 p-4 rounded-lg pt-5">
                <item.Icon />
              </div>
              <h3 dir={isHebrew ? "rtl" : "ltr"}>{item.title[lang]}</h3>
              <p className="text-center">{item.description[lang]}</p>
            </div>
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
};
