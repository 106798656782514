export default {
  title: { en: "FAQ", he: "שאלות נפוצות" },
  heading: { en: "Still have questions?", he: "?עדיין יש לך שאלות" },
  subtitle: {
    en: "Contact us and we'll get back to you as soon as possible.",
    he: '.צור קשר עם ד"ר מעין רחמים ונחזור אליך בהקדם האפשרי',
  },
  contactUs: { en: "Contact us", he: "צור קשר" },
  faqs: [
    {
      id: 1,
      question: {
        en: "What types of treatments do you offer?",
        he: "?מהו בוטוקס",
      },
      answer: {
        en: "We offer a wide range of aesthetic medicine treatments, including Botox, dermal fillers, laser treatments, facelifts, and body contouring.",
        he: 'כאשר אומרים בוטוקס בעצם מתכוונים לחומר הבוטוליניום טוקסין שמופק על ידי חיידק הבוטוליניום. כמו הרבה מתנות מהטבע גם כאן מצא המדע תועלת. כאשר הבוטוקס מוזרק בצורה מקצועית ובמינון נכון הוא מונע מעצבי הפנים באזור המוזרק מלהוליך סיגנל עצבי ועל ידי כך משתק או מפחית את תנועת שרירי הפנים באותו האזור. מכיוון שרבים מקמטי הפנים והמפורסמים ביותר הם קמטי המצח ובין העיניים הם קמטי הבעה , ע"י הזרקת בוטוקס נמנע את הווצרות הקמטים ונטשטש בצורה משמעותית את הקמטים הקיימים.',
      },
    },
    {
      id: 2,
      question: {
        en: "Who is a good candidate for aesthetic medicine?",
        he: "?לכמה זמן מחזיק טיפול הבוטוקס",
      },
      answer: {
        en: "Aesthetic medicine can be beneficial for people of all ages and skin types. A consultation with a qualified practitioner will help determine if you are a good candidate for the specific treatment you are interested in.",
        he: "בוטוקס מחזיק 3-6 חודשים וזה משתנה בין אדם לאדם. למשל, ככל שהאדם מבצע יותר פעילות גופנית כך פירוק הבוטוקס יהיה מהיר יותר. עוד משתנים הינם גיל , אלסטיות העורף עומק הקמט ועוד. החדשות הטובות הן שכאשר הופכים את הטיפול לחלק משגרת טיפוח העור מרווחי הזמן בין טיפול לטיפול גדלי.",
      },
    },
    {
      id: 3,
      question: {
        en: "Is aesthetic medicine safe?",
        he: "?מהן תופעות הלוואי האפשריות בבוטוקס",
      },
      answer: {
        en: "Aesthetic medicine treatments have been extensively researched and are generally considered safe when performed by a qualified professional. However, as with any medical procedure, there can be some risks and side effects. Your practitioner will discuss these with you during your consultation.",
        he: 'כמו בכל טיפול רפואי פולשני יכולות להופיע תופעות לוואי. כבר אוכל להרגיע ולומר שבוטוקס ע"י רופא הינו בטוח במידה ונעשה במיומנות ואך ורק ע"י רופא. חלק מתופעות הלוואי כוללות נפיחות קלה באזור ההזרקה שחולפות זמן קצר לאחר הטיפול, שטפי דם קלים שחולפים תוך מספר ימים , אי סימטריה אותה ניתן לתקן בביקורת וכאב ראש שחולף במהרה ובספונטניות.',
      },
    },
    {
      id: 4,
      question: {
        en: "How long do the results of aesthetic medicine last?",
        he: "?מתי רואים את תוצאות הטיפול בבוטוקס או מילוי",
      },
      answer: {
        en: "The duration of results can vary depending on the specific treatment and individual factors such as skin type and age. Some treatments, such as Botox, require maintenance injections to sustain the results, while others, like facelifts, can have longer lasting effects.",
        he: "בטיפול בבוטוקס ניתן להבחין בשינוי לראות כבר בימים הראשונים לאחר הטיפול כאשר החומר המוזרק משפיע באופן מקסימלי לאחר שבועיים. יש להגיע לביקורת שבועיים לאחר הטיפול על מנת לוודא את השגת התוצאה הרצויה.  במילוי ע״י חומצה ניתן לראות תוצאה חלקית ושינוי מיד עם ההזרקה כאשר התוצאה המקסימלית מושגת כשבועיים לאחר הטיפול.",
      },
    },
    {
      id: 5,
      question: {
        en: "I experience pain during an aesthetic medicine treatment?",
        he: "?מהי חומצה היאלורונית ומהם הטיפולים המתבצעים בעזרתה",
      },
      answer: {
        en: "Pain tolerance varies from person to person, but many aesthetic medicine treatments are minimally invasive and have minimal discomfort. Your practitioner will discuss any potential pain or discomfort with you before the treatment and may use topical numbing cream or other techniques to minimize discomfort.",
        he: "טיפול בחומצה היאלורונית הוא הליך קוסמטי המשמש לשיפור לחות העור, נפחו ומרקמו. חומצה זו נמצאת באופן טבעי המצוי בעור ויורד עם הגיל, מה שמוביל לקמטים וקמטוטים ונפילת העור. ניתן להזריק חומצה היאלורונית כחומר מילוי לטשטוש קמטים כדי להגביר אזורים ספציפיים בפנים כגון הלחיים ועצמות הלחיים, קמטי הבעה וחיוך, קו לסת , שפתיים, סנטר, שקעי העיניים ועוד, וזאת כדי להחזיר מראה צעיר יותר ומטופח. התוצאות הן מיידיות ויכולות להימשך בין 6 חודשים לשנה, תלוי בסוג חומר המילוי המשמש.",
      },
    },
    {
      id: 6,
      question: {
        en: "Will I have to take time off work for an aesthetic medicine treatment?",
        he: "?מהן תופעות הלוואי הנפוצות ביותר לאחר הזרקת חומצה היאלורונית",
      },
      answer: {
        en: "Recovery time for aesthetic medicine treatments can vary depending on the specific procedure. Some treatments, such as Botox and fillers, require no downtime, while others, like facelifts, may require a period of recovery. Your practitioner will discuss any potential recovery time with you before the treatment.",
        he: "?תופעות הלוואי הנפוצות ביותר לאחר הזרקת חומצה היאלורונית הן לרב מעטות ומרביתן חולפות בתוך זמן קצר. לרוב מדובר באדמומיות קלה ונפיחות בנקודות ההזרקה, לעתים בכאב קל שנמשך יום יומיים. במקרה של זיהום מקומי ניתן לטפל באנטיביוטיקה.יש להתייעץ עם רופא לפני ביצוע הטיפול. חשוב לבצע את ההליך אצל רופא מוסמך.",
      },
    },
  ],
};
