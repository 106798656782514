import classNames from "classnames";
import { SectionWrapper } from "components/Section";
import { useLang } from "hooks/useLang";
import { QuestionsBanner } from "pages/LandingPage/sections/LandingPageFAQSection/ui/QuestionsBanner";
import content from "./content";

export const LandingPageFAQSection: React.FunctionComponent = () => {
  const { lang, isHebrew } = useLang();

  return (
    <SectionWrapper id="faq" title={content.title[lang]}>
      <div
        className={classNames(
          "h-full flex flex-col flex-grow justify-between",
          isHebrew && "text-right"
        )}
      >
        <div
          className={classNames(
            "prose prose-md flex gap-x-4 mb-6 flex-wrap w-full",
            isHebrew && "flex-row-reverse"
          )}
        >
          {content.faqs.map((faq) => (
            <div key={faq.id} className="md:basis-1/3 flex-grow">
              <h3 className="text-gray-900">{faq.question[lang]}</h3>
              <p dir={isHebrew ? "rtl" : "ltr"} className="text-gray-700 mb-0">
                {faq.answer[lang]}
              </p>
            </div>
          ))}
        </div>
        <div className="flex-grow h-full flex flex-col justify-end">
          <QuestionsBanner />
        </div>
      </div>
    </SectionWrapper>
  );
};
