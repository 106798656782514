import armpitsImg from "./images/armpits.jpeg";
import botoxImg from "./images/botox.jpeg";
import cheekboneImg from "./images/cheekbone.jpeg";
import foldImg from "./images/folds.jpeg";
import jawlineImg from "./images/jawline.jpeg";
import lipsImg from "./images/lips.jpeg";

export default {
  title: { en: "Treatments", he: "טיפולים" },
  items: [
    {
      id: 1,
      title: { en: "Botox injections", he: "בוטוקס" },
      imageUrl: botoxImg,
    },
    {
      id: 2,
      title: { en: "Jaw line", he: "קו לסת" },
      imageUrl: jawlineImg,
    },
    {
      id: 3,
      title: { en: "Cheekbone", he: "עצמות לחיים" },
      imageUrl: cheekboneImg,
    },
    {
      id: 4,
      title: { en: "Lips augmentation", he: "מילוי שפתיים" },
      imageUrl: lipsImg,
    },

    {
      id: 5,
      title: { en: "Nasolabial folds", he: "מילוי קמטים" },
      imageUrl: foldImg,
    },
    {
      id: 6,
      title: { en: "Excessive sweating", he: "הזעת יתר" },
      imageUrl: armpitsImg,
    },
  ],
};
