import * as React from "react";
import { useLang } from "hooks/useLang";

interface CardProps {
  title: string;
  image: string;
}

export const Card: React.FunctionComponent<CardProps> = ({ title, image }) => {
  const { isHebrew } = useLang();
  return (
    <div className="card px-2">
      <div className="space-y-4">
        <div className="aspect-w-3 aspect-h-2">
          <div
            className="w-full h-64 rounded-md object-cover shadow-md"
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          />
        </div>
        <div className="text-center">
          <div className="text-xl font-medium leading-6 hover:text-primary cursor-pointer mb-4">
            <span>{title}</span>
          </div>
          <span
            dir={isHebrew ? "rtl" : " ltr"}
            className="block md:hidden text-lg underline text-primary"
          >
            {isHebrew ? <> לעוד פרטים &larr;</> : <>Learn more &rarr;</>}
          </span>
        </div>
      </div>
    </div>
  );
};
