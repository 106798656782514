import * as React from "react";
import classNames from "classnames";
import { useLang } from "hooks/useLang";
import image from "./images/1.png";
import content from "./content";

export const TreatmentExcessiveSweatingPage: React.FunctionComponent = () => {
  const { isHebrew, isEnglish, lang } = useLang();

  return (
    <div
      className={classNames(
        "flex flex-col md:flex-row justify-between gap-x-6",
        isEnglish && "md:flex-row-reverse"
      )}
    >
      <img className="md:mt-20 w-full md:w-2/5 h-max" src={image} />

      <article
        dir={isHebrew ? "rtl" : "ltr"}
        className="md:w-3/5 prose lg:prose-md"
      >
        <h1>
          {content.title[lang]}
          <div className="flex mt-[0.5rem]">
            <div className="border-b-2 border-primary w-[100px]" />
          </div>
        </h1>
        <p>{content.paragraph1[lang]}</p>
        <p>{content.paragraph2[lang]}</p>
        <p>{content.paragraph3[lang]}</p>
        <p>{content.paragraph4[lang]}</p>
        <p>{content.paragraph5[lang]}</p>
      </article>
    </div>
  );
};
