export default {
  title: { en: "About Me", he: "קצת על עצמי" },
  name: { en: "dr. Maayan Rahamim", he: 'ד"ר מעיין רחמים' },
  paragraph: {
    en: "I am a highly trained and experienced aesthetic medicine specialist with a passion for helping patients achieve their aesthetic goals and boost their confidence. Here are some key points about my qualifications and experience:",
    he: "מומחה לרפואה אסתטית בעל ההכשרה הגבוהה ביותר בתחום ועם תשוקה גדולה לסייע למטופלות להשיג את התוצאות הטובות ביותר מתוך אמונה שמראה צעיר ומטופח מעלה את תחושת הבטחון העצמי והאושר.",
  },
  bulletsPoints: [
    {
      en: "Received my medical degree from WUM",
      he: 'בעל תארים MD ברפואה, B.sc ביולוגיה ותואר בכלכלה אוניברסיטת ת"א ו WUM.',
    },
    {
      en: "Stay up-to-date with the latest advancements in aesthetic medicine through memberships in professional organizations",
      he: 'מעודכן בהתקדמויות האחרונות בעולם הרפואה האסתטית באמצעות הכשרות בארץ ובחו"ל , חברות בארגוניים מקצועיים ולמידת טכניקות חדשות וקריאת מאמרים מדעיים.',
    },
    {
      en: "Personalized approach to treatment, taking into account the unique concerns and goals of each patient",
      he: "מחויב לשימוש בחומרי המילוי הטובים ביותר בתחום ללא פשרות על איכות הטיפול ובטיחות המטופלת ,על מנת להשיג את התוצאות הטובות ביותר שנשמרות לאורך זמן.",
    },
    {
      en: "Dedicated to providing the highest level of care and service to my patients",
      he: "מאמין בהתאמת טיפול אישי ופרטני לכל מטופלת תוך התחשבות בחששות ובמטרות הייחודיות של כל אחת.",
    },
  ],
};
