import * as React from "react";
import classNames from "classnames";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?: "primary" | "label" | "outline" | "label-white";
  url?: string;
  size?: "small" | "medium" | "large";
  reverse?: boolean;
}

export const Button: React.FunctionComponent<ButtonProps> = ({
  type = "button",
  variant = "primary",
  size = "medium",
  url,
  children,
  className,
  reverse = false,
  ...buttonProps
}) => {
  const classes = classNames(
    "bg-primary hover:bg-primary-dark  font-bold px-4 rounded flex items-center justify-center gap-2",
    size === "large" ? "py-4" : "py-2",
    variant === "label"
      ? "bg-transparent text-inherit border-none hover:bg-transparent py-0 px-0 hover:text-primary"
      : variant === "label-white"
      ? "bg-transparent text-white border-none hover:bg-transparent py-0 px-0 hover:text-primary"
      : variant === "outline"
      ? "bg-transparent text-primary border border-primary"
      : "text-black",
    reverse && "flex-row-reverse",
    className
  );
  return url ? (
    <a href={url} className={classes}>
      {children}
    </a>
  ) : (
    <button type={type} className={classes} {...buttonProps}>
      {children}
    </button>
  );
};
