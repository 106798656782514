import * as React from "react";
import classNames from "classnames";
import { useLang } from "hooks/useLang";

interface SectionWrapperProps {
  id?: string;
  title?: string;
  children: React.ReactNode;
  color?: "white" | "black";
  fullHeight?: boolean;
  background?: string;
}

export const SectionWrapper: React.FunctionComponent<SectionWrapperProps> = ({
  id,
  title,
  color = "black",
  fullHeight = true,
  children,
  background,
}) => {
  const { isHebrew } = useLang();

  return (
    <section
      id={id}
      className={classNames(
        "section w-screen pb-8",
        color === "black" ? "bg-light" : "bg-black",
        background && background
      )}
    >
      <div
        className={classNames(
          "px-6 lg:px-12 max-w-7xl mx-auto flex flex-col md:gap-2 pt-10",
          fullHeight && "min-h-[calc(100vh-5rem)]"
        )}
      >
        {title && (
          <>
            <h1
              className={classNames(
                "text-4xl font-bold",
                id !== "faq" ? "mb-6" : "mb-0",
                color === "black" ? "text-black" : "text-secondary",
                isHebrew ? "text-right" : "text-left title-en"
              )}
            >
              {title}
              {isHebrew && (
                <div className="flex justify-end mt-[0.5rem]">
                  <div className="border-b-2 border-primary w-[100px]" />
                </div>
              )}
            </h1>
          </>
        )}
        {children}
      </div>
    </section>
  );
};
