import { useLocation } from "react-router-dom";
import { Lang } from "lib/types";

export const useLang = (): {
  lang: Lang;
  isHebrew: boolean;
  isEnglish: boolean;
} => {
  const { pathname } = useLocation();
  const pattern = /\/(he|en)\/*/;
  const lang = pathname.match(pattern)?.[1] as Lang;

  const isHebrew = lang === Lang.he;
  const isEnglish = lang === Lang.en;

  return { lang, isHebrew, isEnglish };
};
